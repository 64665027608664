import { useLinks } from '@bluheadless/ui-logic/src/hooks/useLinks'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { cx } from '@emotion/css'
import Editorial from '../../../../../../organisms/editorial'

const BannerEditorial = ({
	aboveTheFold,
	contentType,
	minHeight,
	title,
	titleTag,
	titleColor,
	titleColorMobile,
	subtitle,
	subtitleTag,
	subtitleColor,
	subtitleColorMobile,
	content,
	content2,
	ctas,
	ctaOpenInNewTab,
	image,
	imageConfig,
	sx,
	cssProp,
	className,
	RichContentComponent,
	video,
	videoConfig,
	seoBannerId,
	seoBannerName,
	seoBannerCreative,
	seoBannerPosition,
}) => {
	const reverse = contentType.includes('reverse')

	const links = useLinks([
		{ type: ctas[0]?.linkType, href: ctas[0]?.link },
		{ type: ctas[1]?.linkType, href: ctas[1]?.link },
		{ type: ctas[2]?.linkType, href: ctas[2]?.link },
	])

	const { promotionClick } = useTrackingsActions()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	return (
		<Editorial
			aboveTheFold={aboveTheFold}
			reverse={reverse}
			sx={{ ...sx, minHeight, width: '100%', height: aboveTheFold ? '100%' : undefined }}
			css={cssProp}
			className={cx('Magento-PageBuilder-Banner-Editorial', className)}
			titleProps={{ component: titleTag, sx: { color: { xs: titleColorMobile, md: titleColor } } }}
			title={title}
			subtitleProps={{ component: subtitleTag, sx: { color: { xs: subtitleColorMobile, md: subtitleColor } } }}
			subtitle={subtitle}
			descriptionProps={{ component: 'div' }}
			description={
				content.length > 0 ? <>{content && RichContentComponent && <RichContentComponent content={content} />}</> : null
			}
			description2={
				content2.length > 0 ? (
					<>{content2 && RichContentComponent && <RichContentComponent content={content2} />}</>
				) : null
			}
			imageLink={links[0]}
			imageLinkTarget={ctaOpenInNewTab ? '_blank' : '_self'}
			imageSources={{
				xs: image.mobile,
				md: image.desktop,
			}}
			imageProps={imageConfig}
			cta={ctas.map(({ variant, text, color, openInNewTab }, i) => ({
				variant,
				label: text,
				color,
				colorHex: titleColor,
				href: links[i],
				target: openInNewTab ? '_blank' : '_self',
				onClick: (e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				},
			}))}
			video={video}
			videoConfig={videoConfig}
		/>
	)
}

export default BannerEditorial
