import { styled } from '@mui/system'
import { Box } from '@mui/material'
import {
	Title as HeroBannerTitle,
	Subtitle as HeroBannerSubtitle,
	Description as HeroBannerDescription,
	CtaContainer as HeroBannerCtaContainer,
	Link as HeroBannerLink,
	Button as HeroBannerButton,
} from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) => {
	return !['colorHex', 'variant', 'show', 'reverse', 'aboveTheFold'].includes(prop)
}

const ImageColumn = styled('div')`
	order: ${({ reverse }) => (reverse ? '1' : '2')};
	width: 100%;
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: calc(var(--spacing-1) * 2.5);
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 118px;
	}
`

const Container = styled(Box, {
	shouldForwardProp,
})`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 !important;
	> ${ImageColumn} {
		order: ${({ reverse }) => (reverse ? '2' : '1')};
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		.MuiLink-root {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			order: ${({ reverse }) => (reverse ? '2' : '1')};
		}
	}
	> ${TextColumn} {
		order: ${({ reverse }) => (reverse ? '1' : '2')};

		${({ theme }) => theme.breakpoints.up('md')} {
			order: ${({ reverse }) => (reverse ? '1' : '2')};
		}
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: var(--spacing-2) !important;
	}
`

const CtaContainer = styled(HeroBannerCtaContainer)``
const Link = styled(HeroBannerLink)``
const Button = styled(HeroBannerButton, 'button', { shouldForwardProp })`
	color: ${({ colorHex }) => colorHex};
	&:hover {
		background: ${({ colorHex }) => colorHex};
		color: ${({ colorHex }) => (colorHex == '#ffffff' ? 'var(--color-primary)' : 'var(--color-secondary)')};
	}
	&::after {
		background: ${({ colorHex }) => colorHex};
	}
`

const Title = styled(HeroBannerTitle, { shouldForwardProp })`
	${({ theme, variant }) => theme.typography[variant]}
	--letter-spacing-headlinebig: var(--letter-spacing) !important;
	${({ theme }) => theme.breakpoints.up('md')} {
		--letter-spacing-headlinebig: var(--letter-spacing) !important;
	}
`
const Subtitle = styled(HeroBannerSubtitle)``

const Description = styled(HeroBannerDescription, { shouldForwardProp })`
	${({ theme, variant }) => theme.typography[variant]}
	margin-top: var(--spacing-2);
	padding-top: 0;
	.MuiTypography-root {
		line-height: calc(var(--spacing) * 2.75);
		font-size: var(--font-size-body1);
		letter-spacing: var(--letter-spacing-body);
		${({ theme }) => theme.breakpoints.up('md')} {
			line-height: calc(var(--spacing) * 2.75);
			font-size: var(--font-size-body1);
		}
	}
`

const Description2 = styled(HeroBannerDescription, { shouldForwardProp })`
	${({ theme, variant }) => theme.typography[variant]}
	height: ${({ show }) => (show ? 'fit-content' : '0px')};
	overflow: hidden;
	transition: all 0.2s ease;
	margin-top: var(--spacing-2);
	padding-top: 0;
	.MuiTypography-root {
		line-height: calc(var(--spacing) * 2.75);
		font-size: var(--font-size-body1);
		letter-spacing: var(--letter-spacing-body);
		${({ theme }) => theme.breakpoints.up('md')} {
			line-height: calc(var(--spacing) * 2.75);
			font-size: var(--font-size-body1);
		}
	}
`
const Column = styled('div')`
	flex: 1 1 100%;
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex: 1 1 30%;
	}
`

const ReadMoreWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	pointer-events: auto;
`

const ReadMoreCta = styled('button')`
	all: inherit;
	cursor: pointer;
	text-decoration: none;
	padding: 0;
	display: inline-block;
	width: fit-content;
	padding-right: var(--spacing-3);
	position: relative;
	margin-top: var(--spacing-4);

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 40px;
	}

	&:focus-visible {
		outline: 2px solid;
	}

	::after {
		margin-top: var(--spacing-1);
		content: '';
		display: block;
		width: calc(100% + var(--spacing-3));
		height: 1px;
		background: var(--color-black);
	}
	::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: var(--spacing-2);
		height: var(--spacing-2);
		background: url(data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20font-size%3D%22medium%22%20class%3D%22MuiSvgIcon-root%20MuiSvgIcon-fontSizeMedium%20css-19buqpt-MuiSvgIcon-root%22%20focusable%3D%22false%22%20aria-hidden%3D%22true%22%3E%3Cpath%20d%3D%22M4.768%207.92L12%2016.08l7.232-8.16%22%20stroke%3D%22currentColor%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.536%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
		background-size: cover;
	}

	&.active {
		::before {
			transform: rotate(180deg);
		}
	}
`

export {
	Container,
	ImageColumn,
	TextColumn,
	CtaContainer,
	Button,
	Description,
	Description2,
	Title,
	Subtitle,
	Column,
	ReadMoreWrapper,
	ReadMoreCta,
	Link,
}
